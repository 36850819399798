.content-container {
  padding: 25px 40px;
  text-align: center;
}
.contents {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 0;
}
.buttons {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 3px;
}
.button {
  box-sizing: border-box;
  padding: 10px;
  background-color: transparent;
  border: 1px solid #eaeaea;
  color: #9d9d9d;
}
.active {
  color: white;
  background-color: #f08800;
  border: none;
}

/* ================================================ */

.cardInfo {
  width: 350px;
  height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -400px;
  left: 50%;
  transform: translate(-50%);
  z-index: 5;
  text-align: center;
  padding: 2% 0;
}

.textAreaCancel {
  width: 80%;
}

.textCard {
  margin: 5% 0;
  line-height: 22px;
}

.btnCard {
  margin-top: 5%;
  width: 100%;
  text-align: center;
}

.image-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 
  gap: 7px; 
}

.image {
  width: 300px; 
  height: auto;
  /* max-width: 270px;  */
}
