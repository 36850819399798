.back-main {
    display: flex;
    align-items: center;
    padding: 18px 0;
}

.back-text {
    font-size: 20px;
    font-weight: 600;
    margin-left: 20px;
    display: inline-block;
}

.form-input {
    width: 25em;
    height: 2.8em;
}

.tagline-title {
    font-weight: 700;
    font-size: 18px;
    margin: 8px 0;
}

.tagline {
    margin: 1.5em 0;
}

.success-notif {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 7px;
    margin: 1.5em 0;
}