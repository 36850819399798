.card-btn{
    height: fit-content;
    padding: 0;
  }
  
  .card-info{
    padding: 10px 20px;
    width: 280px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    margin-bottom: 28px;
  }
  
  .card-location {
      border: 0.5px solid #efefef;
      display: grid;
      grid-template-columns: 40% 60%;
      position: relative;
  }
  .card-location-detail {
      /* position: relative; */
      display: flex;
      align-self: center;
      flex-direction: column;
      font-size: 12px;
      padding: 1.3em 3.5em;
  }
  
  .card-location-content {
      margin: 8px 0;
      line-height: 1.4em;
  }
  .hub-copy {
      /* display: flex;
      align-items: center;
      justify-content: space-between; */
      position: absolute;
      bottom: 3em;
      right: 3vw;
  }
  .label-title {
      font-size: 17px;
      margin: 15px 0;
  }
  
  .num, 
  .label-title {
    font-weight: 700;
  }
  .btnSubmit {
      width: 100%;
      height: 40px;
  }
  .container-submit-content {
      text-align: center;
  }
  
  .progress-card-content {
    display: flex;
    justify-content: space-between;
  }
  
  .card-icon-center {
    display: flex;
    margin: 0 auto;
    align-self: center;
    opacity: 0.5;
  }
  .card-bg {
    background-color:  white;
    border: 1px solid #EAEAEA;
    margin-bottom: 15px;
    height: 100%;
    width: 100%;
    padding: 20px;
  }
  
  .progress-card-bg{
    background-color:  #fffaf3;
    margin-bottom: 15px;
    cursor: pointer;
  }
  
  .progress-card-bg-disable{
    background-color: #FAFAFA;
    margin-bottom: 15px;
    color: #898989;
    cursor: not-allowed;
  }
  
  .container-submit-content {
    font-size: 14px;
    color: #b8b8b8;
    margin: 30px 0 20px;
  }
  .num {
    font-size: 24px;
    margin-bottom: 5px;
  }
  
