.navbar {
    width: 100%;
    height: 10vh;
    background-color: #000;
    display: flex;
    position: relative;
    z-index: 10;
    box-shadow: 0 8px 6px -6px black;
  }
  
  .tagline {
    font-size: 20px;
    right: 3em;
    margin-top: 3.5vh;
    color: #fff;
    position: absolute;
    cursor: pointer;
  }
  
  .tagline:hover,
  .tagline:focus {
    text-decoration: underline;
  }
  
  .icon-img {
    margin: auto;
    max-height: 80%;
  }
  
  .version {
    font-size: 14px;
    font-style: italic;
  }
  