/* .app-frame {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: white;
  margin-left: auto;
} */

.app-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: auto;
  /* min-height: 100vh; */
  height: 90vh;
  background-color: white;
}
