.container-select {
    width: 50em;
    margin: 4em auto 0;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.select-hub-button {
    height: 50vh;
    overflow-y: scroll;
    /* overflow-y: hidden; */
}

.container {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    height: 90vh;
  }

    .contextLayout {
    width: 100vw;
    /* border: 1px;
    border-style: solid;
    border-color: black; */
  }

  .signup-bg {
    background-image: var(--page_ssoAccess_bg);
    background-size: cover;
    height: var(--page_ssoAccess_bg_height);
    width: var(--page_ssoAccess_bg_width);
  }
  
.title {
    font-weight: 00;
    font-size: 35px;
    margin-bottom: 1.8em;
}
.choose {
    width: 500; 
    border: 1px solid #C6C6C6; 
    margin: 5% 0
}
.hub-radio {
    width: 30em;
    padding: 2em;
    margin-bottom: 2em;
}

.hub-name {
    margin-top: -15px;
    position: relative;
    text-transform: uppercase;
}

.hub-btn {
    margin: 40px 0;
    width: 30em;
    height: 50px;
    background-color:#F08800;
    color: white;
}